<template>
  <b-container>
    <b-row class="justify-content-md-center text-center">
      <b-col cols="12">
        <div class="text-center center-item">
          <b-img :src="this.qrcode"></b-img>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      qrcode: ''
    }
  },
  created () {
    this.$http.get(`detail-voucher/` + this.$route.params.id)
      .then((result) => {
          this.qrcode = result.data.meta.data.qrcode_path;
          // console.log(this.type);
      }).catch((error) => {
        if (error.response) {
          this.errors.code = error.response.status;
          this.errors.message = error.response.data.meta.message;
          this.errors.status = error.response.data.meta.code;
        }
      });
  },
}
</script>
<style>
  .center-item {
    padding-top: 350px;
  }
</style>
